import { useState } from "react";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import isAfter from "date-fns/isAfter";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import {
  statusAgendamento,
  statusEmAtrasoAgendamento,
  statusCancelamentoAgendamento,
} from "./AgendamentoContext";
import { Box } from "@mui/material";

const getStatus = (a) => {
  if (
    a.status === statusAgendamento.emAberto &&
    isAfter(new Date(), new Date(a.dataAgendamento))
  )
    return <Alert severity="warning">{statusEmAtrasoAgendamento}</Alert>;

  if (Object.values(statusCancelamentoAgendamento).includes(a.status)) {
    return (
      <Typography variant="body2" color="secondary">
        {a.status}
      </Typography>
    );
  }

  return a.status;
};

const AgendmentoList = ({ agendamentos, actions, bulkActions }) => {
  const [selected, setSelected] = useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const update = (id) =>
    isSelected(id)
      ? setSelected(selected.filter((i) => i !== id))
      : setSelected([...selected, id]);

  const updateAll = () =>
    selected.length === agendamentos.length
      ? setSelected([])
      : setSelected(agendamentos.map((a) => a.id));

  return !agendamentos.length ? null : (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {bulkActions && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected.length === agendamentos.length}
                  onChange={() => updateAll()}
                />
              </TableCell>
            )}
            <TableCell>Data</TableCell>
            <TableCell>Setor</TableCell>
            <TableCell>Profissional</TableCell>
            <TableCell>Status</TableCell>
            {actions && <TableCell align="right">Ação</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {agendamentos.map((agendamento) => {
            const status = getStatus(agendamento);
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={agendamento.id}
              >
                {bulkActions && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(agendamento.id)}
                      onChange={() => update(agendamento.id)}
                    />
                  </TableCell>
                )}
                <TableCell>
                  {format(
                    new Date(agendamento.dataAgendamento),
                    "dd/MM/yyyy H:mm"
                  )}
                </TableCell>
                <TableCell>{agendamento.setorAtendimento.nome}</TableCell>
                <TableCell>{agendamento.profissional?.nome}</TableCell>
                <TableCell>{status}</TableCell>
                {actions && (
                  <TableCell align="right" height={69}>
                    {actions(agendamento)}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {selected.length > 0 && bulkActions(selected)}
    </>
  );
};

export default AgendmentoList;
