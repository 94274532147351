import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TodayIcon from "@material-ui/icons/Today";
import NewIcon from "@material-ui/icons/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

import StyledRoot, { classes } from "../../utils/pageStyles";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { usePessoa } from "../Pessoa/PessoaContext";
import AgendamentoList from "../Agendamento/List";
import { Cadastrar } from "./Cadastrar";
import { Cancelar } from "./Cancelar";
import { usePessoaAgendamento } from "./PessoaAgendamentoContext";
import { DesligamentoMessage } from "../PessoaDesligamento/DesligamentoMessage";
import { Box } from "@mui/material";

export function PessoaAgendamento() {
  const { pessoa } = usePessoa();
  const pessoaAgendamento = usePessoaAgendamento();
  const [openCadastro, setOpenCadastro] = useState(false);
  const [agendamento, setAgendamento] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setAgendamento(false);
  }, [pessoaAgendamento.listAgendamento]);

  return (
    <Container component="main" maxWidth="md">
      <Cancelar
        open={!!agendamento}
        onClose={() => setAgendamento(false)}
        agendamento={agendamento}
      />
      <Cadastrar open={openCadastro} onClose={() => setOpenCadastro(false)} />
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <TodayIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Agendamento
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <PessoaEmAtendimento />
            {pessoa.id && !pessoa.desligada && (
              <Grid item xs={12} className={classes.newContainer}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenCadastro(true)}
                  startIcon={<NewIcon />}
                >
                  Novo Agendamento
                </Button>
              </Grid>
            )}
            {pessoaAgendamento.isLoading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            {pessoa.id && !pessoa.desligada && (
              <>
                <Grid item xs={12}>
                  <Alert severity="info">
                    Agendamentos com mais de <b>1 mes</b> de atraso não são
                    listados
                  </Alert>
                </Grid>
              </>
            )}
            <DesligamentoMessage />
            <Grid item xs={12}>
              <AgendamentoList
                agendamentos={pessoaAgendamento.listAgendamento}
                bulkActions={(a) => (
                  <ActionBox>
                    <Typography variant="body2">
                      <b>{a.length} </b>
                      {a.length === 1
                        ? "atendimento selecionado"
                        : "atendimentos selecionados"}
                    </Typography>
                    <Button
                      color="secondary"
                      variant="contained"
                      startIcon={<CancelIcon />}
                      title="Cancelar todos atendimentos selecionados"
                      onClick={() => setAgendamento(a)}
                    >
                      Cancelar selecionados
                    </Button>
                  </ActionBox>
                )}
                actions={(a) => (
                  <>
                    <Button
                      startIcon={<CheckIcon />}
                      title="Iniciar atendimento"
                      onClick={() => {
                        pessoaAgendamento.setAgendamento(a);
                        navigate(`/atendimento/${a.id}`);
                      }}
                    >
                      Iniciar
                    </Button>
                    <Button
                      color="secondary"
                      startIcon={<CancelIcon />}
                      title="Cancelar atendimento"
                      onClick={() => setAgendamento(a)}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </Container>
  );
}

const ActionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: "white",
  padding: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  bottom: 0,
  position: "sticky",
  zIndex: 1,
}));
