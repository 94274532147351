import { useEffect, useState } from "react";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { usePessoa } from "../Pessoa/PessoaContext";
import { LoadingButton } from "../../components/Loading";
import { statusCancelamentoAgendamento } from "../Agendamento/AgendamentoContext";

import { usePessoaAgendamento } from "./PessoaAgendamentoContext";

const motivos = Object.values(statusCancelamentoAgendamento);

/**
 * Cancelar component for managing the cancellation of single or multiple agendamentos
 *
 * @component
 * @param {Object|Array} agendamento - Single agendamento object or array of agendamentos to be cancelled
 * @param {boolean} open - Controls the visibility of the cancellation dialog
 * @param {Function} onClose - Callback function to close the cancellation dialog
 *
 * @returns {React.ReactElement} Cancellation dialog component
 */
export const Cancelar = ({ agendamento, open, onClose }) => {
  const { updateAgendamento, isLoading, cancelListAgendamentos } =
    usePessoaAgendamento();
  const [updatedItem, setUpdatedItem] = useState({});
  const { pessoa } = usePessoa();

  const showCheckbox =
    updatedItem.status === statusCancelamentoAgendamento.naoRealizado ||
    updatedItem.status === statusCancelamentoAgendamento.pacienteNaoCompareceu;

  useEffect(() => {
    setUpdatedItem({});
  }, [agendamento]);

  const onSubmit = async (e) => {
    e.preventDefault();

    Array.isArray(agendamento)
      ? await cancelListAgendamentos(agendamento, updatedItem)
      : await updateAgendamento({ ...agendamento, ...updatedItem });

    onClose();
  };

  if (!updatedItem) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Cancelar agendamento</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box mb={2}>
            <Alert severity="warning">
              <ConfirmationMessage pessoa={pessoa} agendamento={agendamento} />
            </Alert>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={motivos}
                value={
                  motivos.includes(updatedItem.status) ? updatedItem.status : ""
                }
                onChange={(e, status) =>
                  setUpdatedItem({ ...updatedItem, status })
                }
                fullWidth
                autoFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Motivo"
                    variant="outlined"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  setUpdatedItem({ ...updatedItem, descricao: e.target.value })
                }
                value={updatedItem.descricao}
                label="Obs."
              />
            </Grid>
            {showCheckbox && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!updatedItem.ausenciaJustificada}
                      onChange={(e) =>
                        setUpdatedItem({
                          ...updatedItem,
                          ausenciaJustificada: e.target.checked || null,
                        })
                      }
                      value="remember"
                      color="primary"
                    />
                  }
                  label="Ausência justificada"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} type="submit" color="primary">
            Confirmar
          </LoadingButton>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ConfirmationMessage = ({ pessoa, agendamento }) =>
  agendamento && (
    <>
      {Array.isArray(agendamento) ? (
        <>
          Deseja realmente cancelar os <strong>{agendamento.length}</strong>{" "}
          agendamentos de <strong>{pessoa.nome}</strong>?
        </>
      ) : (
        <>
          Deseja realmente cancelar o agendamento de{" "}
          <strong>{pessoa.nome}</strong> para o dia{" "}
          <strong>
            {format(new Date(agendamento.dataAgendamento), "dd/MM/yyyy H:mm")}{" "}
          </strong>
          com o setor de <strong>{agendamento.setorAtendimento.nome}</strong>?
        </>
      )}
      <Box mt={1}>
        <strong>Atenção, esta ação não pode ser revertida!</strong>
      </Box>
    </>
  );
