import { useEffect, useState } from "react";
import { statusAgendamento } from "../Agendamento/AgendamentoContext";
import { Atendimento } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";

export const useFaltasInjustificadas = () => {
  const { user } = useAuth();
  const [faltas, setFaltas] = useState([]);

  console.log(user);

  const load = async () => {
    const { data } = await Atendimento(user.jwt).get({
      status_in: [
        statusAgendamento.naoRealizado,
        statusAgendamento.pacienteNaoCompareceu,
      ],
      ausenciaJustificada_null: true,
      _sort: "dataAgendamento:asc",
    });

    setFaltas(data);
  };

  useEffect(() => {
    load();
  }, [user.user.id]);

  const removeFalta = (id) => {
    setFaltas(faltas.filter((f) => f.id !== id));
  };

  return { faltas, removeFalta };
};
