import { useState, useEffect, createContext, useContext } from "react";
import { format, subMonths } from "date-fns";
import { useMessage } from "../Message/MessageContext";
import { useAuth } from "../Auth/AuthContext";
import { Atendimento } from "../../api/api";
import { usePessoa } from "../Pessoa/PessoaContext";
import { statusAgendamento } from "../Agendamento/AgendamentoContext";
import { usePessoaAgendamentoAnexos } from "./PessoaAgendamentoAnexosContext";

export const defaultData = {
  id: "",
  dataAgendamento: new Date(),
  setorAtendimento: {},
  profissional: {},
  status: statusAgendamento.emAberto,
  dtRecorrencias: [],
};

const PessoaAgendamentoContext = createContext({});

export const usePessoaAgendamento = () => useContext(PessoaAgendamentoContext);

export function PessoaAgendamentoProvider({ children }) {
  const { user } = useAuth();
  const { pessoa } = usePessoa();
  const { setSuccess, setError } = useMessage();
  const [agendamento, setAgendamento] = useState(defaultData);
  const [listAgendamento, setListAgendamento] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { loadAnexos, updateAnexos } = usePessoaAgendamentoAnexos();

  const sortAgendamento = (a, b) =>
    new Date(a.dataAgendamento) - new Date(b.dataAgendamento);

  const load = async () => {
    setIsLoading(true);
    const { data } = await Atendimento(user.jwt).get({
      pessoa: pessoa.id,
      status_contains: statusAgendamento.emAberto,
      dataAgendamento_gte: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
      _sort: "dataAgendamento:asc",
      _limit: -1,
    });

    setListAgendamento(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!pessoa.id || pessoa.desligada) {
      setListAgendamento([]);
      setAgendamento(defaultData);
      return;
    }
    load();
  }, [pessoa.id]);

  const setDtAgendamentos = (dtRecorrencias) =>
    setAgendamento({ ...agendamento, dtRecorrencias });

  const loadAgendamento = async (id) => {
    if (!pessoa.id) return defaultData;

    setIsLoading(true);
    try {
      const { data } = await Atendimento(user.jwt).get({
        pessoa: pessoa.id,
        id,
      });

      await loadAnexos(data[0]);

      return data[0] || defaultData;
    } catch (e) {
      return defaultData;
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (agendamento) => {
    if (agendamento.id) {
      const { data } = await Atendimento(user.jwt).update(
        agendamento.id,
        agendamento
      );

      await updateAnexos(agendamento);

      setListAgendamento(
        [...listAgendamento.filter((a) => a.id !== agendamento.id), data].sort(
          sortAgendamento
        )
      );
      return agendamento.id;
    }

    const { data } = await Atendimento(user.jwt).add({
      ...agendamento,
      pessoa: pessoa.id,
    });

    await updateAnexos(data);
    setListAgendamento([...listAgendamento, data].sort(sortAgendamento));
    return data.id;
  };

  const updateAgendamento = async (agendamento) => {
    setIsLoading(true);
    try {
      const id = await update(agendamento);
      setAgendamento(defaultData);
      setSuccess("Agendamento atualizado com sucesso");
      return id;
    } catch (e) {
      setError("Problema ao tentar atualizar agendamento");
    } finally {
      setIsLoading(false);
    }
  };

  const cancelListAgendamentos = async (agendamentos, status) => {
    setIsLoading(true);
    try {
      await Atendimento(user.jwt).cancelList(agendamentos, status);
      await load();
      setSuccess(`${agendamentos.length} agendamentos cancelados com sucesso`);
    } catch (e) {
      setError("Problema ao cancelar agendamentos");
    } finally {
      setIsLoading(false);
    }
  };

  const saveAtendimento = async (agendamento) => {
    setIsLoading(true);
    try {
      const id = await update({
        ...agendamento,
        status: statusAgendamento.realizado,
        dataAtendimento: new Date(),
      });
      setSuccess("Atendimento salvo com sucesso");
      return id;
    } catch (e) {
      setError("Problema ao salvar atendimento");
    } finally {
      setIsLoading(false);
    }
  };

  const saveAgendamento = async () => {
    setIsLoading(true);
    try {
      const { data } = await Atendimento(user.jwt).add({
        ...agendamento,
        pessoa: pessoa.id,
      });

      const newList = Array.isArray(data)
        ? [...listAgendamento, ...data]
        : [...listAgendamento, data];

      setAgendamento(defaultData);
      setListAgendamento(newList.sort(sortAgendamento));
      setSuccess("Agendamento realizado com sucesso");
    } catch (e) {
      setError("Problema ao tentar realizar agendamento");
    }
    setIsLoading(false);
  };

  const value = {
    cancelListAgendamentos,
    isLoading,
    updateAgendamento,
    saveAgendamento,
    agendamento,
    setAgendamento,
    listAgendamento,
    loadAgendamento,
    saveAtendimento,
    setDtAgendamentos,
  };

  return (
    <PessoaAgendamentoContext.Provider value={value}>
      {children}
    </PessoaAgendamentoContext.Provider>
  );
}
