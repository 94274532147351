import logo from "../Login/logo.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import StyledRoot, { classes } from "../../utils/pageStyles";
import { useAgendamento } from "../Agendamento/AgendamentoContext";

import { Card } from "./Card";
import { useFaltasInjustificadas } from "./useFaltasInjustificadas";

export const Dashboard = () => {
  const {
    agendamentoSemana,
    agendamentoHoje,
    agendamentoMeuHoje,
    agendamentoMeuSemana,
    isLoading,
  } = useAgendamento();

  const { faltas } = useFaltasInjustificadas();

  console.log(faltas);

  return (
    <StyledRoot className={classes.paper}>
      <Box marginBottom={1}>
        <img
          alt="ASSOCIAÇÃO CASA DA ESPERANÇA CUBATÃO Dr. Leão de Moura"
          src={logo}
          width={260}
        />
      </Box>
      <div className={classes.form}>
        <Grid container spacing={2}>
          {agendamentoMeuHoje.length > 0 && (
            <Grid item xs={12} md={6}>
              <Card
                classes={classes}
                title="Meus agendamentos de hoje"
                isLoading={isLoading}
                agendamentos={agendamentoMeuHoje}
                dataFormat="H:mm"
                generateLink={true}
              />
            </Grid>
          )}
          {agendamentoMeuSemana.length > 0 && (
            <Grid item xs={12} md={6}>
              <Card
                classes={classes}
                title="Meus agendamentos da semana"
                isLoading={isLoading}
                agendamentos={agendamentoMeuSemana}
                dataFormat="H:mm"
                generateLink={true}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Card
              classes={classes}
              title="Agendamentos de hoje"
              isLoading={isLoading}
              agendamentos={agendamentoHoje}
              dataFormat="H:mm"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              classes={classes}
              title="Agendamentos da semana"
              isLoading={isLoading}
              agendamentos={agendamentoSemana}
              dataFormat="EEEE H:mm"
            />
          </Grid>
        </Grid>
      </div>
    </StyledRoot>
  );
};
